import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Box } from "@mui/material";

const _margin = 2;

export default function GridPreferences(props) {
  return (
    <Box sx={{ mt: _margin, mb: _margin, ml: _margin, mr: _margin }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              size="small"
              checked={props.shouldDropNa}
              onChange={props.toggleDropNa}
            />
          }
          label="Drop missing values"
        />
      </FormGroup>
    </Box>
  );
}
