import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import Dropzone from "./Dropzone";
import { useSelector, useDispatch } from "react-redux";
// import {
//   setData,
//   setFilters,
//   setHiddenColumns,
//   toggleDropNa,
// } from "../reducers/datasetSlice";
import { df2grid, dataframeToObject, state2grid } from "../utils/datagrid";
import { DataGrid } from "@mui/x-data-grid";
import * as pd from "danfojs";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Popover } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterMenuForm from "./FilterMenuForm";
import CustomColumnMenu from "./GridCustomMenu";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { store } from "../store";
import { state2df } from "../utils/dataframes";
import deepcopy from "../utils/deepcopy";
import { GridToolbarColumnsButton } from "@mui/x-data-grid";
import { useGridRootProps } from "@mui/x-data-grid";
import GridPreferences from "./GridPreferences";
import SettingsIcon from "@mui/icons-material/Settings";

const Toolbar = (props) => {
  console.log("Toolbar")
  console.log(props)
  const columns = props.datasetMeta.data.$columns;
  const dtypes = props.datasetMeta.data.$dtypes;
  const filters = props.datasetMeta.data.$filters;
  const castTypes = props.datasetMeta.data.castTypes;
  const shouldDropNa = props.datasetMeta.data.dropNa;

  const setFilters = props.setFilters;
  const toggleDropNa = props.toggleDropNa;

  const [localFilters, setLocalFilters] = React.useState([...filters]);
  const [filterAnchorEl, setFilterAnchorEl] = React.useState(null);
  const [preferencesAnchorEl, setPreferencesAnchorEl] = React.useState(null);
  const rootProps = useGridRootProps();

  const handleFilterBtnClick = (e) => {
    setFilterAnchorEl(e.currentTarget);
    setLocalFilters([...filters]);
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    const localFiltersToDispatach = [];
    for (let i = 0; i < localFilters.length; i++) {
      if (
        localFilters[i].column != "" &&
        localFilters[i].operator != "" &&
        localFilters[i].value != ""
      ) {
        const filterCopy = { ...localFilters[i] };
        // console.log(filterCopy);
        if (filterCopy.type == "num") {
          // console.log("NUM");
          filterCopy.value = parseFloat(filterCopy.value);
        }
        localFiltersToDispatach.push(filterCopy);
      }
    }
    setFilters(localFiltersToDispatach);
  };

  const handlePreferencesBtnClick = (e) => {
    setPreferencesAnchorEl(e.currentTarget);
  };
  const handlePreferencesClose = () => {
    setPreferencesAnchorEl(null);
  };

  const handleColChange = (id, val) => {
    const filtersCopy = [...localFilters];
    const filter = { ...filtersCopy[id] };
    filter.column = val;
    filter.operator = "";
    filter.value = "";
    filtersCopy[id] = filter;
    setLocalFilters(filtersCopy);
  };

  const handleOpChange = (id, val) => {
    const filtersCopy = [...localFilters];
    const filter = { ...filtersCopy[id] };
    filter.operator = val;
    filtersCopy[id] = filter;
    setLocalFilters(filtersCopy);
  };

  const handleValChange = (id, val, columnType) => {
    const filtersCopy = [...localFilters];
    const filter = { ...filtersCopy[id] };
    filter.value = val;
    filter.type = columnType;
    filtersCopy[id] = filter;
    setLocalFilters(filtersCopy);
  };

  const addFilter = () => {
    const filtersCopy = [...localFilters];
    filtersCopy.push({
      column: columns[0],
      operator: "",
      value: "",
      type: null,
    });
    setLocalFilters(filtersCopy);
  };

  const removeFilter = (id) => {
    const filtersCopy = [...localFilters];
    filtersCopy.splice(id, 1);
    setLocalFilters(filtersCopy);
  };

  const generateForms = () => {
    const forms = [];
    for (let i = 0; i < localFilters.length; i++) {
      const form = (
        <FilterMenuForm
          key={`filter-form-${i}`}
          id={i}
          columns={columns}
          dtypes={dtypes}
          castTypes={castTypes}
          operator={localFilters[i].operator}
          value={localFilters[i].value}
          column={localFilters[i].column}
          handleColChange={handleColChange}
          handleOpChange={handleOpChange}
          handleValChange={handleValChange}
          removeFilter={removeFilter}
        />
      );
      forms.push(form);
    }
    return forms;
  };

  const exportData = () => {
    const data = store.getState().dataset.data;
    const df = state2df(
      deepcopy(data.$columns),
      deepcopy(data.$data),
      data.$dtypes,
      data.$filters,
      data.$target,
      data.castTypes,
      data.hiddenColumns,
      true,
      data.dropNa
    );

    pd.toCSV(df, { fileName: "data.csv", download: true });

    // const csvContent = "data:text/csv;charset=utf-8," + data;
    // const encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);
  };

  const isOpenFilter = Boolean(filterAnchorEl);
  const isOpenPreferences = Boolean(preferencesAnchorEl);

  const filterButton = (
    <rootProps.slots.baseButton
      size="small"
      aria-label={"Filters"}
      aria-describedby={"filter-pannel-button-id-popover"}
      startIcon={<FilterListIcon></FilterListIcon>}
      onClick={handleFilterBtnClick}
      {...rootProps.slotProps?.baseButton}
    >
      {"Filters"}
    </rootProps.slots.baseButton>
  );
  const exportButton = (
    <rootProps.slots.baseButton
      size="small"
      aria-label={"Download as CSV"}
      startIcon={<FileDownloadIcon></FileDownloadIcon>}
      onClick={exportData}
      {...rootProps.slotProps?.baseButton}
    >
      {"Download as CSV"}
    </rootProps.slots.baseButton>
  );
  const preferencesButton = (
    <rootProps.slots.baseButton
      size="small"
      aria-label={"Preferences"}
      aria-describedby={"pref-pannel-button-id-popover"}
      startIcon={<SettingsIcon></SettingsIcon>}
      onClick={handlePreferencesBtnClick}
      {...rootProps.slotProps?.baseButton}
    >
      {"Preferences"}
    </rootProps.slots.baseButton>
  );

  return (
    <Box
      sx={{
        mt: 0.5,
        ml: 1,
      }}
    >
      {filterButton}
      <GridToolbarColumnsButton></GridToolbarColumnsButton>
      {exportButton}
      {preferencesButton}
      <Popover
        id={"filter-pannel-button-id-popover"}
        open={isOpenFilter}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {generateForms()}
        <Box sx={{ p: 1, display: "flex", justifyContent: "flex-start" }}>
          <Button variant="text" size="small" onClick={addFilter}>
            <AddIcon fontSize="small" /> Add filter
          </Button>
        </Box>
      </Popover>

      <Popover
        id={"pref-pannel-button-id-popover"}
        open={isOpenPreferences}
        anchorEl={preferencesAnchorEl}
        onClose={handlePreferencesClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <GridPreferences
          shouldDropNa={shouldDropNa}
          toggleDropNa={toggleDropNa}
        ></GridPreferences>
      </Popover>
    </Box>
  );
};

export default function Dataset(props) {


  const transferFile = async (files) => {
    const file = files[0];
    const df = await pd.readCSV(file);
    props.setData(dataframeToObject(df));
  };

  const makeGrid = () => {
    // const [header, values] = df2grid(data);
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>..... 2")
    console.log(props.datasetMeta.data)

    const [header, values] = state2grid(
      props.datasetMeta.data.$columns,
      props.dataset,
      props.datasetMeta.data.$dtypes,
      props.datasetMeta.data.$filters,
      props.datasetMeta.data.$target,
      props.datasetMeta.data.castTypes,
      props.datasetMeta.data.dropNa
    );
    const onColumnVisibilityModelChange = (model) => {
      const obj_ = {
        columns: Object.keys(model).filter((i) => !model[i] && i !== "id"),
      };
      props.setHiddenColumns(obj_);
    };
    return (
      <>
        <DataGrid
          rows={values}
          columns={header}
          slots={{
            toolbar: (toolbarProps) => <Toolbar {...toolbarProps} dataset={props.dataset} datasetMeta={props.datasetMeta} setFilters={props.setFilters} toggleDropNa={props.toggleDropNa} setTarget={props.setTarget} />,
            columnMenu: (columnProps) => <CustomColumnMenu {...columnProps} datasetMeta={props.datasetMeta} addCast={props.addCast} setTarget={props.setTarget} />
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 100,
              },
            },
          }}
          density="compact"
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
          disableColumnFilter
          onColumnVisibilityModelChange={onColumnVisibilityModelChange}
          columnVisibilityModel={Object.fromEntries(
            props.datasetMeta.data.hiddenColumns.map((c) => [c, false])
          )}
        ></DataGrid>
      </>
    );
  };
  return (


    <>
      {props.dataset === null ? (
        <Dropzone
          transferFile={transferFile}
          quantity={1}
          maxSize={20 * 1024 * 1024}
          allowedTypes={["text/csv"]}
        />
      ) : (
        makeGrid()
      )}
    </>
  );
}
