import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";

const initialState = {
  data: {
    $data: [{}],
    $columns: [],
    $dtypes: [],
    $filters: [],
    $target: null,
    empty: true,
    castTypes: {},
    hiddenColumns: [], 
    dropNa: false,
  },
};

export const datasetSlice = createSlice({
  name: "dataset",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = { ...action.payload, empty: false, castTypes: {}, hiddenColumns: [], dropNa: false};
    },
    setFilters: (state, action) => {
      state.data.$filters = action.payload;
    },
    setTarget: (state, action) => {
      state.data.$target = action.payload;
    },
    addCast: (state, action) => {
      state.data.castTypes[action.payload.field] = action.payload.type;
      const f = (filter) => {
        return filter.column != action.payload.field;
      }
      state.data.$filters = state.data.$filters.filter(f)
      },
    setHiddenColumns: (state, action) => {
      state.data.hiddenColumns = action.payload.columns;
    },
    toggleDropNa: (state, action) => {
      console.log('toggle Drop Na')
      state.data.dropNa = !state.data.dropNa;
    }
  },
});

export const { setData, setFilters, setTarget, addCast, setHiddenColumns, toggleDropNa } = datasetSlice.actions;

export default datasetSlice.reducer;
