// src/App.js

import React from 'react';
import { Grid, Box, Button, Paper, Typography, Toolbar, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import Chart from 'react-apexcharts';

export const RegressionReport = (props) => {
    const trainingMetrics = {
        mse: 10.5,
        rmse: 3.24,
        mae: 2.15,
        r2: 0.85, // Total Score for training
    };

    const testMetrics = {
        mse: 12.3,
        rmse: 3.51,
        mae: 2.45,
        r2: 0.80, // Total Score for test
    };

    // Feature Importance Data
    const featureImportanceData = {
        options: {
            chart: {
                type: 'bar',
            },
            xaxis: {
                categories: ['Feature A', 'Feature B', 'Feature C', 'Feature D', 'Feature E'],
            },
            title: {
                text: 'Feature Importances',
                align: 'center',
            },
        },
        series: [
            {
                name: 'Importance',
                data: [30, 20, 40, 10, 50],
            },
        ],
    };

    // Radial Gauge Chart for total score
    const radialOptions = {
        options: {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    hollow: {
                        margin: 15,
                        size: '70%',
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            fontSize: '24px',
                            color: '#000',
                            offsetY: 10,
                        },
                    },
                },
            },
            labels: ['Score'],
        },
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <IconButton color="primary" >
                    <SaveIcon />
                </IconButton>
                <IconButton color="primary" onClick={props.download}>
                    <DownloadIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2, overflowY: 'auto', maxHeight: "73vh" }}>
                <Grid container spacing={3} alignItems="stretch">
                    {/* MSE Cards */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training MSE
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.mse}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training MAE
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.mae}</Typography>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training RMSE
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.rmse}</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>

                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training R² Score
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.r2}</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>

                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test MSE
                            </Typography>
                            <Typography variant="h4">{testMetrics.mse}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test MAE
                            </Typography>
                            <Typography variant="h4">{testMetrics.mae}</Typography>
                        </Paper>
                    </Grid>

                    {/* R² Cards */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test RMSE
                            </Typography>
                            <Typography variant="h4">{testMetrics.rmse}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test R² Score
                            </Typography>
                            <Typography variant="h4">{testMetrics.r2}</Typography>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }} style={{ height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Total Score
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Chart
                                        options={radialOptions.options}
                                        series={[trainingMetrics.r2 * 100]} // R² in percentage for radial chart
                                        type="radialBar"
                                        height={280}
                                    />
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Training Set
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Chart
                                        options={radialOptions.options}
                                        series={[testMetrics.r2 * 100]} // R² in percentage for radial chart
                                        type="radialBar"
                                        height={280}
                                    />
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Test Set
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3 }} style={{ height: '100%' }}>
                            <Chart
                                options={featureImportanceData.options}
                                series={featureImportanceData.series}
                                type="bar"
                                height={280}
                            />
                        </Paper>
                    </Grid>

                </Grid>
            </Box >
        </>
    );
};