export function getCatIcon(cat){
    if(cat === 'num'){
        return "🔢"
    }
    else if(cat === 'cat'){
        return "🔤"
    }
    else if(cat === 'dt' || cat === 'datetime'){
        return "🕒"
    }
    else{
        return "❓"
    }
}