import * as pd from "danfojs";

// export function applyFilter(df, filter){

// }

function state2JSON(columns, arr) {
  return arr.map((arr) =>
    Object.assign({}, ...arr.map((x, i) => ({ [columns[i]]: x })))
  );
}

function filterDf(df, filter) {
  let query;
  if (filter.operator === "<=") {
    query = df[filter.column].le(filter.value);
  } else if (filter.operator === ">=") {
    query = df[filter.column].ge(filter.value);
  } else if (filter.operator === "==") {
    query = df[filter.column].eq(filter.value);
  } else if (filter.operator === "!=") {
    query = df[filter.column].ne(filter.value);
  } else if (filter.operator === "<") {
    query = df[filter.column].lt(filter.value);
  } else if (filter.operator === ">") {
    query = df[filter.column].gt(filter.value);
  }
  return query;
}

function dropColumns(df, hideColumns, hiddenColumns) {
  if (hideColumns && hiddenColumns.length > 0) {
    df.drop({ columns: hiddenColumns, inplace: true });
  }
}

export function state2df(
  columns,
  values,
  dtypes,
  filters,
  target,
  castTypes,
  hiddenColumns,
  hideColumns,
  dropNa
) {
  let df = new pd.DataFrame(values, { columns: columns });
  for (const [key, value] of Object.entries(castTypes)) {
    if (value === "dt" || value === "datetime") {
      const series = pd.toDateTime(df[key].$data);

      const dt = series.$dateObjectArray.map((x) => {
        try {
          return x.toISOString().slice(0, -5) + "Z";
        } catch (e) {
          return NaN;
        }
      });

      df[key] = dt;
      continue;
    }
    let castTo = "float32";
    if (value === "cat") {
      castTo = "string";
    }
    df = df.asType(key, castTo);
  }
  df.ctypes.print();
  const queries = [];
  for (let i = 0; i < filters.length; i++) {
    queries.push(filterDf(df, filters[i]));
  }
  if (dropNa) {
    df = df.dropNa();
  }
  if (queries.length === 0) {
    dropColumns(df, hideColumns, hiddenColumns);
    return df;
  }
  let queryAccumulator = queries[0];
  for (let i = 1; i < queries.length; i++) {
    queryAccumulator = queryAccumulator.and(queries[i]);
  }
  df = df.query(queryAccumulator);
  dropColumns(df, hideColumns, hiddenColumns);
  return df;
}
