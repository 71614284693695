import React from 'react';
import {
    Box,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Paper,
    Button,
} from '@mui/material';

export function Tmp() {
    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={4}>
                    {/* Model Configuration */}
                    <Paper sx={{ padding: 2, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Model Configuration
                        </Typography>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel id="model-select-label">Model</InputLabel>
                            <Select labelId="model-select-label" label="Model">
                                <MenuItem value="model1">Model 1</MenuItem>
                                <MenuItem value="model2">Model 2</MenuItem>
                                <MenuItem value="model3">Model 3</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="API Key" variant="outlined" fullWidth />
                    </Paper>

                    {/* Model Config */}
                    <Paper sx={{ padding: 2, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Model Config
                        </Typography>
                        <TextField
                            label="Model Configuration"
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                        />
                    </Paper>

                    {/* External Knowledge Configuration */}
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            External Knowledge Configuration
                        </Typography>
                        {/* Drag and Drop Area */}
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                borderRadius: 1,
                                padding: 2,
                                textAlign: 'center',
                                color: 'text.secondary',
                            }}
                        >
                            <Typography>Drag and drop documents here</Typography>
                            <Button variant="contained" sx={{ marginTop: 2 }}>
                                Browse Files
                            </Button>
                        </Box>
                    </Paper>
                </Grid>

                {/* Right Side */}
                <Grid item xs={8}>
                    <Paper
                        sx={{
                            padding: 2,
                            height: '80vh',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Chat
                        </Typography>
                        <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 2 }}>
                            {/* Chat messages would be displayed here */}
                        </Box>
                        <TextField
                            placeholder="Type your message..."
                            variant="outlined"
                            fullWidth
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}

