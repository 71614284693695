import React, { useState } from 'react';
import { Container, Box } from '@mui/material';
import TabularPredictorFlow from '../flows/TabularPredictorFlow';

export default function TabularRegression() {
    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
            }}
        >
            <Container maxWidth={false} sx={{ mt: 10, mb: 4 }}>
                <TabularPredictorFlow variant="regression" />
            </Container>
        </Box>
    );
}