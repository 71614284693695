import { state2df } from "./dataframes";
import deepcopy from "./deepcopy";
import { getCatIcon } from "./icons";
import * as pd from "danfojs";

export function dataframeToObject(df) {
  const obj = {
    $columns: df["$columns"],
    $data: df["$data"],
    $dtypes: df["$dtypes"],
    $filters: [],
    $target: df["$columns"][df["$columns"].length - 1],
  };
  return obj;
}

// export function df2grid(df) {
//   const header = df["$columns"];
//   const values = df["$data"];
//   const dtypes = df["$dtypes"];
//   const transformedValues = [];

//   for (let row = 0; row < values.length; row++) {
//     const transformedRow = {};
//     transformedRow["id"] = row;
//     for (let column = 0; column < header.length; column++) {
//       transformedRow[header[column]] = values[row][column];
//       // if (['int32', 'int64', 'float32', 'float64'].includes(dtypes[column])){
//       //   console.log('Cast')
//       //   transformedRow[header[column]] = Number(transformedRow[header[column]])
//       // }
//     }
//     transformedValues.push(transformedRow);
//   }

//   const gridHeader = [
//     { field: "id", headerName: "id", width: 90, type: "number" },
//   ];
//   for (let column = 0; column < header.length; column++) {
//     const gridHeaderColumn = {};
//     gridHeaderColumn.field = header[column];
//     gridHeaderColumn.gridHeaderColumn = header[column];
//     gridHeaderColumn.width = 150;
//     gridHeaderColumn.editable = false;
//     let type = "string";
//     console.log(dtypes[column]);
//     if (["int32", "int64", "float32", "float64"].includes(dtypes[column])) {
//       type = "number";
//     }
//     console.log(type);
//     gridHeaderColumn.type = type;
//     gridHeader.push(gridHeaderColumn);
//   }

//   console.log(gridHeader);
//   console.log(transformedValues);
//   return [gridHeader, transformedValues];
// }

export function state2grid(
  columns,
  originalValues,
  dtypes,
  filters,
  target,
  castTypes,
  dropNa
) {

  console.log(columns, originalValues, dtypes, filters, target, castTypes, dropNa)
  const df = state2df(
    deepcopy(columns),
    deepcopy(originalValues),
    dtypes,
    filters,
    target,
    castTypes,
    [],
    false,
    dropNa
  );

  const header = df["$columns"];
  const values = df["$data"];
  
  
  const ids = Array.from(Array(values.length).keys());

  df.addColumn("id", ids, {inplace: true});
  const transformedValues = pd.toJSON(df, {format: "column"});

  const gridHeader = [
    { field: "id", headerName: "id", width: 90, type: "number" },
  ];
  for (let column = 0; column < header.length; column++) {
    const gridHeaderColumn = {};
    gridHeaderColumn.field = header[column];
    gridHeaderColumn.minWidth = 200;
    gridHeaderColumn.flex = 1;
    gridHeaderColumn.editable = false;
    let type = "cat";
    if (["int32", "int64", "float32", "float64"].includes(dtypes[column])) {
      type = "num";
    }
    if (columns[column] in castTypes) {
      type = castTypes[columns[column]];
    }
    const icon = getCatIcon(type);
    gridHeaderColumn.headerName = header[column] + " " + icon;
    gridHeaderColumn.headerName =
      header[column] === target
        ? gridHeaderColumn.headerName + " 🎯"
        : gridHeaderColumn.headerName;
    gridHeaderColumn.type = type;
    gridHeader.push(gridHeaderColumn);
  }

  return [gridHeader, transformedValues];
}
