import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import TimelineTwoToneIcon from '@mui/icons-material/TimelineTwoTone';

import { Link } from "react-router-dom";

export const mainListItems = (
  <React.Fragment>
    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton>
        <ListItemIcon>
          <TimelineTwoToneIcon />
        </ListItemIcon>
        <ListItemText primary="Train" />
      </ListItemButton>
    </Link>

    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
      <ListItemButton>
        <ListItemIcon>
          <RestoreOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="History" />
      </ListItemButton>
    </Link>

  </React.Fragment>
);

export const secondaryListItems = <React.Fragment></React.Fragment>;
