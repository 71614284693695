import React, { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    TextField,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Paper,
    Button,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';

export function RagQAFlow() {
    // State for chat messages
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');

    // State for uploaded documents
    const [documents, setDocuments] = useState([]);

    // State to track if the model is thinking
    const [isModelThinking, setIsModelThinking] = useState(false);

    // Handle sending a message
    const handleSendMessage = () => {
        if (inputMessage.trim() === '') return;

        // Add user's message to chat
        setMessages((prevMessages) => [
            ...prevMessages,
            { sender: 'user', text: inputMessage },
        ]);

        setInputMessage('');

        // Set isModelThinking to true
        setIsModelThinking(true);

        // Simulate model's response after a delay
        setTimeout(() => {
            // Set isModelThinking to false
            setIsModelThinking(false);

            setMessages((prevMessages) => [
                ...prevMessages,
                {
                    sender: 'model', text: `AlpenEco Solutions GmbH provides the following solutions:
    \n
1. Renewable Energy Solutions: Specialized in decentralized, renewable energy projects (wind, solar, and hydroelectric) tailored for alpine conditions, promoting energy self-sufficiency in rural areas.
    \n
2. Sustainable Waste Management: Focuses on waste-to-energy technologies, converting organic and municipal waste into clean energy. They also have a recycling division that supports businesses in reducing industrial waste and promoting zero-waste practices.
   \n
3. Eco-friendly Construction: Pioneers in green building materials and modular designs that minimize resource consumption, including insulation from recycled materials and energy-efficient architecture.` },
            ]);
        }, 2000); // 2-second delay
    };

    // Handle input change
    const handleInputChange = (event) => {
        setInputMessage(event.target.value);
    };

    // Handle Enter key press
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage();
        }
    };

    // Handle file drop
    const handleDrop = (event) => {
        event.preventDefault();
        const files = Array.from(event.dataTransfer.files);
        setDocuments((prevDocuments) => [...prevDocuments, ...files]);
    };

    // Handle drag over
    const handleDragOver = (event) => {
        event.preventDefault();
    };

    // Handle file selection via button
    const handleFileSelect = (event) => {
        const files = Array.from(event.target.files);
        setDocuments((prevDocuments) => [...prevDocuments, ...files]);
    };

    // TypingIndicator component
    function TypingIndicator() {
        const [dots, setDots] = useState('');

        useEffect(() => {
            const interval = setInterval(() => {
                setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : ''));
            }, 500);
            return () => clearInterval(interval);
        }, []);

        return (
            <ListItem
                alignItems="flex-start"
                sx={{
                    justifyContent: 'flex-start',
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#e8eaf6',
                        borderRadius: 2,
                        padding: 1,
                        maxWidth: '80%',
                    }}
                >
                    <ListItemText
                        primary={
                            <Typography variant="body2" color="textPrimary">
                                .{dots}
                            </Typography>
                        }
                    />
                </Box>
            </ListItem>
        );
    }

    return (
        <Box sx={{ flexGrow: 1, padding: 2 }}>
            <Grid container spacing={2}>
                {/* Left Side */}
                <Grid item xs={4}>
                    {/* Model Configuration */}
                    <Paper sx={{ padding: 2, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            Model Configuration
                        </Typography>
                        <FormControl fullWidth sx={{ marginBottom: 2 }}>
                            <InputLabel id="model-select-label">Model</InputLabel>
                            <Select labelId="model-select-label" label="Model">
                                <MenuItem value="model1">GPT-4o</MenuItem>
                                <MenuItem value="model2">GPT-4o-mini</MenuItem>
                                <MenuItem value="model3">Llama 3.1 8B</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="API Key" variant="outlined" fullWidth />
                    </Paper>

                    {/* Model Config */}
                    <Paper sx={{ padding: 2, marginBottom: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            System Prompt
                        </Typography>
                        <TextField variant="outlined" multiline rows={4} fullWidth />
                    </Paper>

                    {/* External Knowledge Configuration */}
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            External Knowledge Configuration
                        </Typography>
                        {/* Drag and Drop Area */}
                        <Box
                            sx={{
                                border: '2px dashed grey',
                                borderRadius: 1,
                                padding: 2,
                                textAlign: 'center',
                                color: 'text.secondary',
                                mb: 2,
                            }}
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                        >
                            <Typography>Drag and drop documents here</Typography>
                            <Button variant="contained" component="label" sx={{ marginTop: 2 }}>
                                Browse Files
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleFileSelect}
                                />
                            </Button>
                        </Box>
                        {/* Display list of uploaded documents */}
                        {documents.length > 0 && (
                            <Paper
                                variant="outlined"
                                sx={{ maxHeight: 200, overflowY: 'auto' }}
                            >
                                <List dense>
                                    {documents.map((file, index) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={file.name} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        )}
                    </Paper>
                </Grid>

                {/* Right Side */}
                <Grid item xs={8}>
                    <Paper
                        sx={{
                            padding: 2,
                            height: '80vh',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Chat
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                overflowY: 'auto',
                                marginBottom: 2,
                                paddingRight: 1,
                            }}
                        >
                            {/* Display chat messages */}
                            <List>
                                {messages.map((message, index) => (
                                    <ListItem
                                        key={index}
                                        alignItems="flex-start"
                                        sx={{
                                            justifyContent:
                                                message.sender === 'user'
                                                    ? 'flex-end'
                                                    : 'flex-start',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor:
                                                    message.sender === 'user'
                                                        ? '#e0f7fa'
                                                        : '#e8eaf6',
                                                borderRadius: 2,
                                                padding: 1,
                                                maxWidth: '80%',
                                            }}
                                        >
                                            <ListItemText
                                                primary={
                                                    <Typography
                                                        variant="body2"
                                                        color="textPrimary"
                                                    >
                                                        <div style={{ whiteSpace: "pre-line" }}>{message.text}</div>
                                                    </Typography>
                                                }
                                            />
                                        </Box>
                                    </ListItem>
                                ))}
                                {/* Typing Indicator */}
                                {isModelThinking && <TypingIndicator />}
                            </List>
                        </Box>
                        <TextField
                            placeholder="Type your message..."
                            variant="outlined"
                            fullWidth
                            multiline
                            maxRows={4}
                            value={inputMessage}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendMessage}
                            sx={{ marginTop: 1 }}
                        >
                            Send
                        </Button>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
}
