import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import TransformIcon from "@mui/icons-material/Transform";
import {
  DataGrid,
  GridColumnMenu,
  GridColumnMenuProps,
  GridColumnMenuItemProps,
} from "@mui/x-data-grid";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import { DialogContent, Divider } from "@mui/material";
import AbcIcon from "@mui/icons-material/Abc";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import NumbersIcon from "@mui/icons-material/Numbers";

function cast(field, type) {
  return { field: field, type: type };
}

function CustomUserItem(props) {
  const activeField = props.activeField;

  if (props.activeField == "id") {
    return <></>;
  }
  return (
    <>
      <MenuItem onClick={() => props.setTarget(activeField)}>
        <ListItemIcon>
          <ModeStandbyIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Set as target</ListItemText>
      </MenuItem>
      <Divider />
      <MenuItem onClick={() => props.addCast(cast(activeField, "num"))}>
        <ListItemIcon>
          <NumbersIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Convert to numerical</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => props.addCast(cast(activeField, "cat"))}>
        <ListItemIcon>
          <AbcIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Convert to categorical</ListItemText>
      </MenuItem>
      <MenuItem onClick={() => props.addCast(cast(activeField, "dt"))}>
        <ListItemIcon>
          <AccessTimeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Convert to datetime</ListItemText>
      </MenuItem>
    </>
  );
}

export default function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Add new item
        columnMenuUserItem: (menuProps) => <CustomUserItem {...menuProps} setTarget={props.setTarget} addCast={props.addCast} />,
      }}
      slotProps={{
        columnMenuUserItem: {
          // set `displayOrder` for the new item
          displayOrder: 30,
          // Additional props
          myCustomValue: "Do custom action",
          activeField: props.colDef.field,
        },
      }}
    />
  );
}
