import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { Input } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const OPERATORS = {
  num: ["", "==", "!=", ">", ">=", "<", "<="],
  cat: ["", "==", "!="],
  dt: ["", "==", "!="],
};

function get_dtype(column, columns, dtypes, castTypes) {
  for (let i = 0; i < columns.length; i++) {
    if (columns[i] == column) {
      // first check casts 
      if (columns[i] in castTypes) {
        return castTypes[columns[i]];
      }
      // then check dtypes
      if (["int32", "int64", "float32", "float64"].includes(dtypes[i])) {
        return "num";
      } else {
        return "cat";
      }
    }
  }
}

export default function FilterMenuForm(props) {
  //   const [id, columns, dtypes, operator, value] = props;
  const id = props.id;
  const columns = props.columns;
  const dtypes = props.dtypes;
  const column = props.column;
  const castTypes = props.castTypes;
  const columnType = get_dtype(column, columns, dtypes, castTypes);
  const operator = props.operator;
  const value = props.value;
  let operators = OPERATORS[columnType];

  const removeFilter = () => {
    props.removeFilter(id);
  };

  const handleColChange = (e) => {
    props.handleColChange(id, e.target.value, columnType);
  };

  const handleOpChange = (e) => {
    props.handleOpChange(id, e.target.value);
  };

  const handleValChange = (e) => {
    let value = e.target.value;
    props.handleValChange(id, value, columnType);
  };

  return (
    <Box sx={{ p: 1, display: "flex", justifyContent: "center" }}>
      <Select
        style={{ verticalAlign: "bottom" }}
        variant="standard"
        sx={{ mr: 1, width: "100px", height: "40px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={column}
        label="Column"
        onChange={handleColChange}
        // InputProps={{
        //   readOnly: false,
        // }}
      >
        {columns.map((column) => (
          <MenuItem key={`columnSelector_${id}_${column}`} value={column}>
            {column}
          </MenuItem>
        ))}
      </Select>
      <Select
        style={{ verticalAlign: "bottom" }}
        variant="standard"
        sx={{ mr: 1, width: "100px", height: "40px" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={operator}
        label="operator"
        onChange={handleOpChange}
      >
        {operators.map((op) => (
          <MenuItem key={`operatorSelector_${id}_${op}`} value={op}>
            {op}
          </MenuItem>
        ))}
      </Select>
      <Box sx={{ mr: 1 }} style={{ verticalAlign: "bottom" }}>
        <Input
          type="text"
          value={value}
          onChange={handleValChange}
          sx={{ width: "100px", height: "40px" }}
        />
      </Box>
      <Box sx={{ mr: 1, mt: 1, height: "40px" }}>
        {" "}
        <Button onClick={removeFilter}>
          <DeleteForeverIcon></DeleteForeverIcon>
        </Button>
      </Box>
    </Box>
  );
}
