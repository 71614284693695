
let idCounter = 0;
const callbacks = {};

export const TABULAR_CLASSIFIATION = "tabular_classification"
export const TABULAR_REGRESSION = "tabular_regression"

export async function sendMessage(message, payload) {
  idCounter += 1;

  return new Promise((resolve, reject) => {
    callbacks[idCounter] = (response) => {
      resolve(response);
    };

    window.pyodeideWorker.postMessage({
      message: message,
      id: idCounter,
      payload: payload,
    });
  });
}

export default async function loadPyodide() {
  if (window.pyodideStartedLoading) {
    return false;
  }
  window.pyodideStartedLoading = true;
  window.pyodeideWorker = new window.Worker("/webworker.js");
  window.pyodeideWorker.onmessage = async (event) => {
    const m = event.data
    const callback = callbacks[m.id]
    delete callbacks[m.id];
    console.log("payload")
    console.log(m.payload)
    callback(m.payload)
  }
  return true;
}