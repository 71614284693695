import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import { CircularProgress } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Picker from "./pages/Picker";
import Dataset from "./components/Dataset";
import loadPyodide from "./loadPyodide";
import "./App.css";
import Menu from "./Menu";
import TabularClassification from "./pages/TabularClassification";
import TabularRegression from "./pages/TabularRegression";
import { Tmp } from "./pages/Tmp";
import RagQA from "./pages/RagQA";

const mdTheme = createTheme();

function AppContent() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Menu></Menu>
          <Routes>
            <Route path="/" element={<Picker />} />
            <Route path="/tmp" element={<Tmp />} />
            <Route path="/ml/tabular_classification" element={<TabularClassification />} />
            <Route path="/ml/tabular_regression" element={<TabularRegression />} />
            <Route path="/ml/rag_qa" element={<RagQA />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default function App() {
  const [isPythonLoaded, setPythonLoaded] = React.useState(false);
  React.useEffect(() => {
    const load = async () => {
      const res = await loadPyodide();
      if (res) {
        setPythonLoaded(true);
      }
    };
    if (!isPythonLoaded) {
      load();
    }
  });

  if (isPythonLoaded) {
    return <AppContent />;
  } else {
    return (
      <div id="app-load-spinner">
        <CircularProgress size={100} />
      </div>
    );
  }
}
