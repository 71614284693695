import { useDropzone } from "react-dropzone";
import "./Dropzone.css";
import React, { useCallback, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Alert } from "@mui/material";

function Dropzone(props) {
  const [errorText, setErrorText] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length !== props.quantity) {
      setErrorText(
        `ERROR: Expected ${props.quantity} file(s) to be uploaded, got ${acceptedFiles.length}.`
      );
      return;
    }

    for (let i = 0; i < acceptedFiles.length; i++) {
      if (acceptedFiles[i].size > props.maxSize) {
        setErrorText(`ERROR: Uploaded file exceeded maximum filesize.`);
        return;
      }
      if (!props.allowedTypes.includes(acceptedFiles[i].type)) {
        setErrorText(
          `ERROR: Invalid file type. File type must be one of ${props.allowedTypes}.`
        );
        return;
      }
      props.transferFile(acceptedFiles)
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const border = !isDragActive ? "3px #1976d2 dashed" : "3px #1976d2 solid";
  return (
    <>
      <div
        {...getRootProps({ className: "dropzone" })}
        style={{ border: border }}
      >
        <input className="input-zone" {...getInputProps()} />

        <div className="text-center">
          <p className="dropzone-content">
            <CloudUploadIcon fontSize="large"></CloudUploadIcon>
            <br></br>
            Drag and drop some files here, or click to select files
          </p>
        </div>
      </div>
      {errorText ? (
        <Alert severity="error" sx={{ mt: 2 }}>
          {errorText}
        </Alert>
      ) : (
        <></>
      )}
    </>
  );
}

export default Dropzone;
