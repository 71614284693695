// src/App.js

import React from 'react';
import { Grid, Box, Button, Paper, Typography, Toolbar, IconButton } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Chart from 'react-apexcharts';


export const ClassificationReport = (props) => {
    const trainingMetrics = {
        bacc: 0.95,
        prec: 0.95,
        recall: 0.95,
        f2: 0.95,
        score: 0.95
    };

    const testMetrics = {
        bacc: 0.91,
        prec: 0.91,
        recall: 0.91,
        f2: 0.91,
        score: 0.91

    };

    // Feature Importance Data
    const featureImportanceData = {
        options: {
            chart: {
                type: 'bar',
            },
            xaxis: {
                categories: ['Petal Length', 'Petal Width', 'Sepal Length', 'Sepal Width'],
            },
            title: {
                text: 'Feature Importances',
                align: 'center',
            },
        },
        series: [
            {
                name: 'Importance',
                data: [43, 43, 10, 4],
            },
        ],
    };

    // Radial Gauge Chart for total score
    const radialOptions = {
        options: {
            chart: {
                type: 'radialBar',
            },
            plotOptions: {
                radialBar: {
                    startAngle: -135,
                    endAngle: 135,
                    hollow: {
                        margin: 15,
                        size: '70%',
                    },
                    dataLabels: {
                        name: {
                            show: false,
                        },
                        value: {
                            fontSize: '24px',
                            color: '#000',
                            offsetY: 10,
                        },
                    },
                },
            },
            labels: ['Score'],
        },
    };

    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                <IconButton color="primary" onClick={props.predict}>
                    <PlayArrowRoundedIcon />
                </IconButton>
                <IconButton color="primary" >
                    <SaveIcon />
                </IconButton>
                <IconButton color="primary" onClick={props.download}>
                    <DownloadIcon />
                </IconButton>

            </Box>
            <Box sx={{ p: 2, overflowY: 'auto', maxHeight: "73vh" }}>
                <Grid container spacing={3} alignItems="stretch">
                    {/* MSE Cards */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training BACC
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.bacc}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training PREC
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.prec}</Typography>
                        </Paper>

                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training RECALL
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.recall}</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>

                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Training F2 Score
                            </Typography>
                            <Typography variant="h4">{trainingMetrics.f2}</Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={3}>

                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test BACC
                            </Typography>
                            <Typography variant="h4">{testMetrics.bacc}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test PREC
                            </Typography>
                            <Typography variant="h4">{testMetrics.prec}</Typography>
                        </Paper>
                    </Grid>

                    {/* R² Cards */}
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test RECALL
                            </Typography>
                            <Typography variant="h4">{testMetrics.recall}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Paper elevation={3} sx={{ p: 3 }}>
                            <Typography variant="h6" gutterBottom>
                                Test F2 Score
                            </Typography>
                            <Typography variant="h4">{testMetrics.f2}</Typography>
                        </Paper>
                    </Grid>


                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3, textAlign: 'center' }} style={{ height: '100%' }}>
                            <Typography variant="h6" gutterBottom>
                                Total Score
                            </Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <Chart
                                        options={radialOptions.options}
                                        series={[trainingMetrics.bacc * 100]} // R² in percentage for radial chart
                                        type="radialBar"
                                        height={280}
                                    />
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Training Set
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Chart
                                        options={radialOptions.options}
                                        series={[testMetrics.bacc * 100]} // R² in percentage for radial chart
                                        type="radialBar"
                                        height={280}
                                    />
                                    <Typography variant="body1" sx={{ mt: 2 }}>
                                        Test Set
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={3} sx={{ p: 3 }} style={{ height: '100%' }}>
                            <Chart
                                options={featureImportanceData.options}
                                series={featureImportanceData.series}
                                type="bar"
                                height={280}
                            />
                        </Paper>
                    </Grid>

                </Grid>
            </Box >
        </>
    );
};