import React, { useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, CardMedia, Button, Box } from '@mui/material';
import { TextFields } from '@mui/icons-material'; // Importing icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import TimelineRoundedIcon from '@mui/icons-material/TimelineRounded';
import WaterfallChartRoundedIcon from '@mui/icons-material/WaterfallChartRounded';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ImageRoundedIcon from '@mui/icons-material/ImageRounded';
import { useNavigate } from 'react-router-dom';

const TaskPicker = () => {
    // State to keep track of selected task
    const [selectedTask, setSelectedTask] = useState('');
    const navigate = useNavigate();

    // List of model classes with icons
    const modelClasses = [
        { value: 'tab_classification', label: 'Tabular Classification', icon: <CategoryRoundedIcon style={{ fontSize: 60 }} /> },
        { value: 'tab_regression', label: 'Tabular Regression', icon: <TimelineRoundedIcon style={{ fontSize: 60 }} /> },
        { value: 'forecast', label: 'Forecasting', icon: <WaterfallChartRoundedIcon style={{ fontSize: 60 }} /> },
        { value: 'zero_shot_text_classification', label: 'Zero-Shot Text Classification', icon: <TextFields style={{ fontSize: 60 }} /> },

        { value: 'rag_qa', label: 'Conversational QA', icon: <QuestionAnswerIcon style={{ fontSize: 60 }} /> },
        { value: 'ner', label: 'Named Entity Recognition', icon: <DriveFileRenameOutlineIcon style={{ fontSize: 60 }} /> },
        { value: 'image_classification', label: 'Image Classification', icon: <ImageRoundedIcon style={{ fontSize: 60 }} /> },
        { value: 'agent', label: 'Autonomous Agent', icon: <SupportAgentIcon style={{ fontSize: 60 }} /> },
    ];

    // Handle task selection
    const handleTaskChange = (value) => {
        setSelectedTask(value);
    };

    // Handle continue button click
    const handleContinue = () => {
        if (selectedTask) {
            if (selectedTask === "tab_classification") {
                navigate("/ml/tabular_classification")
            } else if (selectedTask === "tab_regression") {
                navigate("/ml/tabular_regression")
            } else if (selectedTask === "rag_qa") {
                navigate("/ml/rag_qa")
            }
            else {
                alert('Task not implemented yet');
            }
            // Continue to next step
        } else {
            alert('Please select a task');
        }
    };

    return (
        <Box
            component="main"
            sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[900],
                flexGrow: 1,
                height: "100vh",
                overflow: "auto",
            }}
        >
            <Container maxWidth={false} sx={{ mt: 10, mb: 4 }}>

                <Typography variant="h4" align="center" gutterBottom>
                    Pick a Machine Learning Task
                </Typography>

                <Grid container justifyContent="center" spacing={4}>
                    {modelClasses.map((task) => (
                        <Grid item xs={12} sm={6} md={3} key={task.value}>
                            <Card
                                onClick={() => handleTaskChange(task.value)}
                                style={{
                                    border: selectedTask === task.value ? '2px solid #3f51b5' : '1px solid #ccc',
                                    cursor: 'pointer',
                                    padding: '20px',
                                    textAlign: 'center',
                                    transition: '0.3s',
                                    boxShadow: selectedTask === task.value ? '0 4px 8px rgba(0,0,0,0.3)' : '0 1px 3px rgba(0,0,0,0.2)',
                                    height: '200px', // Ensures all cards have the same height
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <CardContent>
                                    <CardMedia>
                                        {task.icon}
                                    </CardMedia>
                                    <Typography variant="h6" align="center" gutterBottom style={{ marginTop: '10px' }}>
                                        {task.label}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

                <Grid container justifyContent="center" style={{ marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleContinue}
                        disabled={!selectedTask}
                    >
                        Continue
                    </Button>
                </Grid>
            </Container>
        </Box>
    );
};

export default TaskPicker;
